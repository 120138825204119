.container {
  width: 90%;
  margin: 5% auto 0;
  height: 0;
  padding-bottom: 56.25%;
}

.youtubeWrapper {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  border-radius: 15px;
  filter: drop-shadow(0px 0px 21px rgba(38, 40, 106, 0.7));
  overflow: hidden;
  margin: auto;
}

.video {
  position: absolute;
  width: 100%;
  height: 100%;
}
