@import "@cs/styles";

$border-radius: 8px;

@mixin Padding {
  padding: 1rem;

  @include media(">desktop") {
    padding: 2rem;
  }
}

.section {
  background-color: #fff;
}

.textContent,
.cards {
  margin-inline: auto;
  max-width: 1200px;
  @include Padding;

  @include media(">1700px") {
    max-width: 1800px;
  }
}

.heading,
.subHeading {
  width: auto;
  color: $mediumBlue;
}

.heading {
  text-align: center;
  font-weight: 700;
  font-size: 40px;
  line-height: 1;

  @include media(">desktop") {
    text-align: left;
  }
}

.subHeading {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;

  @include media(">bigPhone") {
    font-size: 17px;
    max-width: 700px;
    margin-inline: auto;
  }

  @include media(">desktop") {
    text-align: left;
    font-size: 22px;
    max-width: 1050px;
    margin-inline: 0;
  }
}

.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  @include media(">desktop") {
    flex-direction: row;
    justify-content: space-between;
  }
}

.card {
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  width: 75vw;
  max-width: 400px;
  border-radius: $border-radius;
  overflow: hidden;
  filter: drop-shadow(0px 4px 39px rgba(0, 0, 0, 0.2));

  @include media(">tablet") {
    width: 400px;
  }

  @include media(">desktop") {
    height: 375px;
    width: auto;
    aspect-ratio: 1;
  }

  @include media(">bigDesktop") {
    height: 420px;
  }
}

.figure {
  position: relative;
  flex: 1;
  width: 100%;
  margin: 0;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 20%;
  background-color: white;
  padding: 1rem;

  * {
    margin: 0;
  }

  @include media(">bigPhone") {
    padding: 1.5rem;
  }

  @include media(">tablet") {
    padding: 1rem;
  }

  @include media(">desktop") {
    flex-basis: 30%;
    padding: 1.5rem;
  }
}

.h4 {
  font-size: 28px;
  margin-bottom: 0.4rem;
  color: $mediumBlue;

  @include media(">desktop") {
    font-size: 32px;
  }
}

.p {
  font-size: 17px;

  @include media(">desktop") {
    font-size: 18px;
  }
}

.timeline {
  margin-top: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: linear-gradient(104.7deg, #fbf9ff 0%, #e9edff 100%);

  @include media(">2000px") {
    margin-top: 15rem;
  }

  @include media("<=tablet") {
    margin-top: 1rem;
  }

  & > * {
    z-index: 1;
  }

  .figure {
    display: none;
    position: absolute;
    width: 100%;
    top: -150px;
    width: 100%;
    min-width: 100%;
    z-index: 0;

    @include media(">tablet") {
      display: block;
    }

    @include media(">2000px") {
      top: -250px;
    }
  }
}

.h3 {
  margin: 0;
  text-align: center;
  font-size: 35px;
  color: $mediumBlue;
  font-weight: 400;
  padding: 1rem 2rem 0 2rem;

  strong {
    font-size: 45px;
  }

  @include media("<=tablet") {
    font-size: 30px;
    padding: 5rem 1rem 0 1rem;

    strong {
      font-size: 35px;
    }
  }

  @include media("<=bigPhone") {
    padding: 3rem 1rem 0 1rem;

    strong {
      font-size: 35px;
    }
  }
}

.steps {
  background-color: #becfff;
  color: $mediumBlue;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: left;
  gap: 2rem;
  padding: 2rem;
  border-radius: 9px;
  margin-block: 2rem;

  @include media(">tablet") {
    flex-direction: row;
    width: 75vw;
    margin-block: 3rem;
    gap: 0;
  }

  @include media(">desktop") {
    min-width: 800px;
    max-width: 900px;
    margin-block: 5rem;
  }
}

.step {
  max-width: 280px;

  @include media(">tablet") {
    max-width: 160px;
    margin-bottom: 0;
  }

  @include media(">desktop") {
    max-width: 200px;
    margin-bottom: 0;
  }
}

.step_title {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  margin: 0 0 0.5rem 0;

  @include media(">desktop") {
    font-size: 22px;
  }
}

.step_text {
  font-size: 28px;
  font-weight: 700;
  margin: 0;

  @include media(">desktop") {
    font-size: 37px;
  }
}

.button {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 auto 3rem;
  width: auto;
}
