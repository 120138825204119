@import "@cs/styles";

.images {
  margin: 2rem 8%;

  @include media("<=tablet") {
    margin: 2rem;
  }
}

.image {
  max-width: 200px;

  @include media("<=1200px") {
    max-width: 180px;
  }

  @include media("<=tablet") {
    max-width: 200px;
  }
}

.arrow {
  font-size: 40px;
}

.container {
  h2 {
    text-align: center;
    margin: 1.5rem auto;
    color: $mediumBlue;
    font-weight: 700;
    font-size: 25px;

    @include media("<=bigPhone") {
      font-size: 25px;
    }
  }
}

.sectionPartners {
  padding: 5rem 0 4rem;
  min-width: 1200px;
  background-color: #ffffff;

  @include media("<=1300px") {
    min-width: 1000px;
  }

  @include media("<=1100px") {
    min-width: 900px;
  }

  @include media("<=1000px") {
    padding: 3rem auto;
    min-width: 500px;
  }

  @include media("<=tablet") {
    padding: 2rem auto;
  }

  @include media("<=550px") {
    min-width: 300px;
  }

  &-blue {
    @extend .sectionPartners;

    .partners {
      color: $mediumBlue;
    }
  }

  &-grey {
    @extend .sectionPartners;

    .partners {
      font-family: Causten, "Roboto", sans-serif;
      color: $cs-dark-grey;
      font-size: 55px;
      font-weight: 800;

      @include media("<=bigDesktop") {
        font-size: 45px;
      }

      @include media("<=tablet") {
        font-size: 40px;
      }
    }
  }
}

.partners {
  margin: 0;
  height: fit-content;
  color: $purple5;

  text-align: center;
  font-size: 35px;

  max-width: 100%;

  @include media("<=1300px") {
    font-size: 35px;
  }

  @include media("<=800px") {
    text-align: center;
    max-width: 100%;
  }
}
