@import "@cs/styles";

.section {
  position: relative;
  background-color: #fff;
  padding: 5rem 10%;

  @include media("<=1200px") {
    padding: 3rem 2rem;
  }

  @include media("<=850px") {
    padding: 2rem 1rem;
  }
}

.h3 {
  color: $mediumBlue;
  font-size: 45px;
  text-align: center;

  @include media("<=tablet") {
    font-size: 35px;
  }

  @include media("<=bigPhone") {
    font-size: 30px;
  }
}

.quote {
  position: relative;
  padding: 1.5rem 1rem;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0rem;
  overflow: visible;

  @include media(">desktop") {
    background-color: $purple7;
    color: #fff;
    margin-top: 3rem;
    padding: 3rem 1rem;
    max-height: 296px;
    max-width: 1211px;
    gap: 3rem;
    flex-direction: row;
  }

  .text {
    padding: 0.5rem 0;
    font-size: 20px;
    font-style: italic;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    max-width: 650px;
    text-align: center;

    @include media(">desktop") {
      padding: 0.5rem 1rem;
      margin-left: 300px;
      text-align: unset;
    }
  }
}

.figure {
  position: relative;
  background-color: $blue;
  aspect-ratio: 1;
  margin: 0;
  width: 100%;
  max-width: 340px;
  border-radius: 15px;
  overflow: hidden;

  @include media(">desktop") {
    width: auto;
    max-width: none;
    height: 340px;
    min-width: 200px;
    position: absolute;
    aspect-ratio: 5/7;
    top: -30px;
    left: 30px;
  }
}
