@import "@cs/styles";

.container {
  position: relative;
  background: linear-gradient(
    104.7deg,
    rgba(249, 250, 255, 0.85) 0%,
    rgba(178, 191, 245, 0.85) 100.55%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
  min-height: 0;
}

.curvedBackgroundContainer {
  position: absolute;
  bottom: -5px;
  width: 100%;
  min-width: 100%;
  overflow-x: hidden !important;
  z-index: 1;
}

.treesContainer {
  position: absolute;
  width: 100%;
  bottom: 0px;
  right: 0;
  z-index: 0;
  overflow-x: hidden !important;

  @include media(">bigDesktop") {
    width: 90%;
  }
}

.mainContent {
  margin: 0 5% 144px;
  max-width: 1200px;

  display: flex;
  flex-direction: column;

  @include media(">bigPhone") {
    margin-inline: 1rem;
  }

  @include media(">desktop") {
    margin: 2em 6% 144px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  @include media(">bigDesktop") {
    margin: 1em 4% 200px;
    justify-content: space-around;
  }

  @include media(">1700px") {
    max-width: 1800px;
    justify-content: space-between;
  }
}

.textContent,
.youtube {
  height: fit-content;
  align-self: center;
}

.textContent {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  @include media(">desktop") {
    display: block;
  }
}

.heading,
.subHeading {
  width: auto;
  color: $mediumBlue;
}

.heading {
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  line-height: 1;
  max-width: 100%;
  margin-bottom: 1rem;

  @include media(">bigPhone") {
    font-size: 32px;
  }

  @include media(">tablet") {
    font-size: 38px;
    max-width: 550px;
  }

  @include media(">desktop") {
    font-size: 40px;
    max-width: 450px;
    margin-bottom: 1.5rem;
    margin-left: 0;
    text-align: left;
  }

  @include media(">bigDesktop") {
    font-size: 45px;
    max-width: 520px;
  }
}

.subHeading {
  text-align: center;
  font-size: 14px;
  line-height: 1.3;
  max-width: 550px;
  margin: 0 auto 1rem;

  @include media(">bigPhone") {
    font-size: 17px;
  }

  @include media(">tablet") {
    max-width: 550px;
    margin: 1rem 0 1.5rem;
  }

  @include media(">desktop") {
    max-width: 405px;
    font-size: 17px;
    text-align: left;
  }

  @include media(">bigDesktop") {
    font-size: 20px;
  }
}

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 18px;
  line-height: 10px;
  color: #fff;
  background: linear-gradient(90deg, #110f27 0%, #2b2563 100%);
  max-width: fit-content;
  font-weight: 700 !important;
  padding: 13px 20px !important;

  @include media(">desktop") {
    margin: 0 !important;
    font-size: 22px !important;
    margin: 0;
    width: auto;
    max-width: fit-content;
  }
}

.youtube {
  display: flex;
  justify-content: center;
  position: relative;
  width: 98vw;

  div > {
    filter: none !important;
    box-shadow: none !important;
  }

  @include media(">bigPhone") {
    max-width: 500px;
  }

  @include media(">desktop") {
    max-width: 520px;
  }

  @include media(">bigDesktop") {
    max-width: 640px;
  }
}
